var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-table',{attrs:{"loading":_vm.loading,"headings":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.navigateUser(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-eye-circle-outline ")]),_vm._v(" View Logs ")],1)],1)],1)],1)]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("secToHuman")(item.total || 0," hours", " minutes"))+" ")])]}},{key:"user.real_name",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('avatar',{attrs:{"size":"35","user":item.user,"with-name":""}}):_vm._e()]}},{key:"user.email",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('span',[_vm._v(_vm._s(item.user.email))]):_vm._e()]}},{key:"user.status",fn:function(ref){
var item = ref.item;
return [(item.user && item.user.status === 'in')?_c('chip-in',{attrs:{"label":""}}):(item.user && item.user.status === 'brb')?_c('chip-brb',{attrs:{"label":""}}):(item.user && item.user.status === 'out')?_c('chip-out',{attrs:{"label":""}}):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }